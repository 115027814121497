@use '@/scss/underscore' as _;

.resourceLayout {
  height: 100%;
}

.header {
  padding: _.unit(6) _.unit(8);
  display: flex;
  align-items: center;
  justify-content: space-between;

  > *:not(:first-child) {
    margin-left: _.unit(6);
  }

  .metadata {
    flex: 1;

    > div {
      display: flex;
      align-items: center;

      > *:not(:last-child) {
        margin-right: _.unit(2);
      }
    }

    .name {
      font: var(--font-title-1);
      color: var(--color-text);
    }

    .suspended {
      background: var(--color-error-container);
      color: var(--color-text);
      font: var(--font-label-3);
      padding: _.unit(0.5) _.unit(1.5);
      border-radius: 10px;
    }

    .username {
      color: var(--color-text-secondary);
      font: var(--font-label-2);
    }

    .text {
      font: var(--font-label-2);
      color: var(--color-text-secondary);
    }

    .verticalBar {
      @include _.vertical-bar;
      height: 12px;
    }
  }

  .moreIcon {
    color: var(--color-text-secondary);
  }
}


.resetIcon {
  color: var(--color-text-secondary);
}
