import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
class AppInsightsReact {
    get instance() {
        return this.appInsights;
    }
    get trackPageView() {
        return this.appInsights?.trackPageView.bind(this.appInsights);
    }
    setup() {
        // The string needs to be normalized since it may contain '"'
        const connectionString = process.env.APPLICATIONINSIGHTS_CONNECTION_STRING?.replace(/^"?(.*)"?$/g, '$1');
        if (!connectionString) {
            return false;
        }
        if (this.appInsights?.config.connectionString === connectionString) {
            return true;
        }
        try {
            // https://github.com/microsoft/applicationinsights-react-js#readme
            this.reactPlugin = new ReactPlugin();
            this.appInsights = new ApplicationInsights({
                config: {
                    connectionString,
                    enableAutoRouteTracking: false,
                    extensions: [this.reactPlugin],
                },
            });
            this.appInsights.loadAppInsights();
        }
        catch (error) {
            console.error('Unable to init ApplicationInsights:');
            console.error(error);
            return false;
        }
        return true;
    }
    withAppInsights(Component) {
        if (!this.reactPlugin) {
            return Component;
        }
        return withAITracking(this.reactPlugin, Component, undefined, 'appInsightsWrapper');
    }
}
export const appInsightsReact = new AppInsightsReact();
