const users = {
    page_title: 'ユーザー管理',
    title: 'ユーザー管理',
    subtitle: 'ユーザーのアイデンティティを管理する、ユーザーの作成、情報の編集、ユーザーログの表示、パスワードのリセットおよびユーザーの削除を含む',
    create: 'ユーザーを追加する',
    user_name: 'ユーザー名',
    application_name: 'アプリケーションから',
    latest_sign_in: '最新のサインイン',
    create_form_username: 'ユーザー名',
    create_form_password: 'パスワード',
    create_form_name: 'フルネーム',
    unnamed: '名前がありません',
    search: '名前、メール、電話、またはユーザー名で検索',
    check_user_detail: 'ユーザー詳細を確認する',
    placeholder_title: 'ユーザー管理',
    placeholder_description: '各ユーザーには、基本データ、ソーシャルアイデンティティ、およびカスタムデータが含まれるプロフィールがあります。',
};
export default users;
