const users = {
    page_title: 'Benutzerverwaltung',
    title: 'Benutzerverwaltung',
    subtitle: 'Verwalten von Benutzeridentitäten, einschließlich des Anlegens von Benutzern, Bearbeiten von Benutzerinformationen, Anzeigen von Benutzer-Logs, Zurücksetzen von Passwörtern und Löschen von Benutzern',
    create: 'Benutzer hinzufügen',
    user_name: 'Benutzer',
    application_name: 'Anwendungsname',
    latest_sign_in: 'Letzte Anmeldung',
    create_form_username: 'Benutzername',
    create_form_password: 'Passwort',
    create_form_name: 'Name',
    unnamed: 'Unbenannt',
    search: 'Suche nach Name, E-Mail, Telefon oder Benutzername',
    check_user_detail: 'Benutzerdetail prüfen',
    placeholder_title: 'Benutzerverwaltung',
    placeholder_description: 'Jeder Benutzer hat ein Profil mit allen Benutzerinformationen. Es besteht aus grundlegenden Daten, sozialen Identitäten und benutzerdefinierten Daten.',
};
export default users;
