const users = {
    page_title: '사용자 관리',
    title: '사용자 관리',
    subtitle: '사용자의 신원을 추가, 삭제, 수정, 조회하여 관리해보세요.',
    create: '사용자 추가',
    user_name: '사용자',
    application_name: '어플리케이션으로부터',
    latest_sign_in: '최근 로그인 시각',
    create_form_username: '사용자 이름',
    create_form_password: '비밀번호',
    create_form_name: '이름',
    unnamed: '이름없음',
    search: '이름, 이메일, 전화번호, ID로 검색',
    check_user_detail: '사용자 상세정보 확인',
    placeholder_title: '사용자 관리',
    placeholder_description: '각 사용자에게는 모든 사용자 정보가 포함된 프로필이 있어요. 프로필은 기본 데이터, 소셜 인증, 사용자 지정 데이터로 구성돼요.',
};
export default users;
