@use '@/scss/underscore' as _;

.item {
  display: flex;
  align-items: center;
  white-space: nowrap;

  > div:not(:first-child) {
    margin-left: _.unit(3);
  }

  .content {
    padding-right: _.unit(4);
    overflow: hidden;

    .title {
      display: block;
      font: var(--font-body-2);
      color: var(--color-text-link);
      text-decoration: none;
      @include _.text-ellipsis;
    }

    .subtitle {
      font: var(--font-body-3);
      color: var(--color-text-secondary);
      @include _.text-ellipsis;
    }
  }

  &.compact {
    .content {
      display: flex;
      align-items: baseline;

      .title {
        margin-right: _.unit(1);
      }
    }
  }
}
