@use '@/scss/underscore' as _;

.item {
  display: flex;
  align-items: center;
  padding: _.unit(2.5) _.unit(4);
  cursor: pointer;
  user-select: none;

  .name {
    flex: 1 1 0;
    font: var(--font-body-2);
    @include _.text-ellipsis;
    margin-left: _.unit(2);
  }

  &:hover {
    background: var(--color-hover);
  }
}
