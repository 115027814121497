const user_details = {
    page_title: 'Kullanıcı detayları',
    back_to_users: 'Kullanıcı Yönetimine Geri Dön',
    created_title: 'Bu kullanıcı başarıyla oluşturuldu',
    created_guide: 'Kullanıcıya aşağıdaki oturum açma bilgilerini gönderebilirsiniz',
    created_username: 'Kullanıcı Adı:',
    created_password: 'Şifre:',
    menu_delete: 'Sil',
    delete_description: 'Bu işlem geri alınamaz. Kullanıcıyı kalıcı olarak siler.',
    deleted: 'Kullanıcı başarıyla silindi.',
    reset_password: {
        reset_password: 'Şifreyi sıfırla',
        title: 'Şifreyi sıfırlamak istediğinizden emin misiniz?',
        content: 'Bu işlem geri alınamaz. Bu, kullanıcının oturum açma bilgilerini sıfırlayacaktır.',
        congratulations: 'Bu kullanıcı sıfırlandı',
        new_password: 'Yeni şifre:',
    },
    tab_settings: 'Ayarlar',
    tab_roles: 'Roller',
    tab_logs: 'Kullanıcı kayıtları',
    settings: 'Ayarlar',
    settings_description: 'Her kullanıcının, temel veriler, sosyal kimlikler ve özel verilerden oluşan tüm kullanıcı bilgilerini içeren bir profil vardır.',
    field_email: 'Öncelikli e-posta adresi',
    field_phone: 'Öncelikli telefon',
    field_username: 'Kullanıcı Adı',
    field_name: 'İsim',
    field_avatar: 'Avatar resmi URLi',
    field_avatar_placeholder: 'https://your.cdn.domain/avatar.png',
    field_custom_data: 'Özel veriler',
    field_custom_data_tip: 'Kullanıcı tarafından tercih edilen renk ve dil gibi önceden tanımlanmış kullanıcı özelliklerinde listelenmeyen ek kullanıcı bilgileri.',
    field_connectors: 'Sosyal bağlayıcılar',
    custom_data_invalid: 'Özel veriler geçerli bir JSON nesnesi olmalıdır',
    connectors: {
        connectors: 'Bağlayıcılar',
        user_id: 'Kullanıcı IDsi',
        remove: 'Kaldır',
        not_connected: 'Kullanıcı herhangi bir sosyal bağlayıcıya bağlı değil',
        deletion_confirmation: 'Mevcut <name/> kimliğini kaldırıyorsunuz. Bunu yapmak istediğinizden emin misiniz?',
    },
    suspended: 'Askıya alınmış',
    roles: {
        name_column: 'Rol',
        description_column: 'Açıklama',
        assign_button: 'Rolleri Ata',
        delete_description: 'Bu işlem, bu rolü bu kullanıcıdan kaldıracaktır. Rol kendisi hala var olacaktır, ancak artık bu kullanıcıyla ilişkili olmayacaktır.',
        deleted: '{{name}} bu kullanıcıdan başarıyla kaldırıldı.',
        assign_title: '{{name}} kullanıcısına roller atayın',
        assign_subtitle: '{{name}} bir veya daha fazla rolü yetkilendirin',
        assign_role_field: 'Rolleri ata',
        role_search_placeholder: 'Rol adına göre arama yapın',
        added_text: '{{value, number}} eklendi',
        assigned_user_count: '{{value, number}} kullanıcı',
        confirm_assign: 'Rolleri ata',
        role_assigned: 'Rol(ler) başarıyla atandı',
        search: 'Rol adına, açıklamasına veya Kimliğine göre arama yapın',
        empty: 'Uygun rol yok',
    },
};
export default user_details;
